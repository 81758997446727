<template>
  <div :class="patternType === 1?'blackSubject':'topicPage'">
    <HeadBar :patternType="patternType" :titleName="titleName"></HeadBar>
    <div class="content oepnX">
      <van-tabs
          v-if="navList && navList.length"
          v-model="activeName"
          animated
          class="navTabs oepnX"
          line-height="6"
          line-width="24"
          swipeable
          title-active-color="#333333"
          title-inactive-color="rgba(153, 153, 153, 1)"
          type="card"
          @change="clickTag"
      >
        <van-tab v-for="(item, index) in navList" :key="index">
          <template #title>
            <span
                :class="{ navTabTitleActive: activeName == index }"
                class="navTabTitle"
            >
              {{ item.title }}
            </span>
          </template>
          <div class="topicPageContent">
            <PullRefresh
                v-if="!isLoading"
                :error="error"
                :finished="finished"
                :hasAdvPagination="true"
                :is-higeht-max="true"
                :isNoData="isNoData"
                :loading="loading"
                :refreshing="refreshing"
                className="TopicPage"
                @onLoad="onLoad"
                @onRefresh="onRefresh"
            >
              <!--              <VerticleThreeList-->
              <!--                :showAdv="true"-->
              <!--                :videoList="videoList"-->
              <!--                v-if="format == 0 && videoList.length"-->
              <!--              >-->
              <!--              </VerticleThreeList>-->
              <!--              <TransverselyTwoList-->
              <!--                :videoList="videoList"-->
              <!--                v-else-->
              <!--              ></TransverselyTwoList>-->

              <div v-if="isAcg" class="rowsBox">
                <HorizontalVideoBox
                    v-for="(item,index) in videoList"
                    :key="item.id"
                    :currentIndex="index"
                    :patternType="patternType"
                    :isAcg="isAcg"
                    :videoInfo="item"
                    :videoList="videoList"
                    class="videoBox"
                />
              </div>
              <div v-else class="videoRowsBox">
                <HorizontalVideoBox
                    v-for="(item,index) in videoList"
                    :key="item.id"
                    :currentIndex="index"
                    :patternType="patternType"
                    :isAcg="isAcg"
                    :videoInfo="item"
                    :videoList="videoList"
                    class="videoBox"
                />
              </div>
            </PullRefresh>
            <Loading v-else />
          </div>
        </van-tab>
      </van-tabs>
    </div>
  </div>
</template>

<script>
// import VerticleThreeList from "@/components/VideoList/VerticleThreeList";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import { AdType, getAdItem, jumpAdv } from "@/utils/getConfig";
import {queryAcgMediaList, queryMediaList, queryVideoMediaList} from "@/api/video";
import { moduleListMore } from "@/api/home";
import Loading from "@/components/Loading";
import PullRefresh from "@/components/PullRefresh";
import HeadBar from "@/components/HeadBar";
import HorizontalVideoBox from "@/components/HorizontalVideoBox/index.vue";
import ImgDecypt from "@/components/ImgDecypt/index.vue";
export default {
  name: "TopicPage",
  components: {
    HorizontalVideoBox,
    // VerticleThreeList,
    // TransverselyTwoList,
    Loading,
    PullRefresh,
    HeadBar,
  },
  data() {
    return {
      activeName: 1,
      pageNumber: 1,
      pageSize: 12,
      topicId: "",
      titleName: "",
      format: 0,
      tabNmae: "",
      name: "",
      advNum: 0,
      videoList: [],
      oldVideoList: [],
      listAdvs: [],
      listAdvsVerticel: [],
      isLoading: true,
      loading: true,
      refreshing: false,
      finished: false,
      isNoData: false,
      advList: [],
      error: false,
      isAcg: false,
      patternType: 0,
      navList: [
        {
          title: "最新",
          sortType: 1,
        },
        // {
        //   title: "推荐",
        //   key: 2,
        // },
        // {
        //   title: "逛逛",
        //   key: 3,
        // },
        {
          title: "最热",
          sortType: 2,
        },
      ],
    };
  },
  created() {
    // console.log('初始化')
    // this.listAdvs = getAdItem(AdType.TIPIC_MORE);
    // this.listAdvsVerticel = getAdItem(AdType.TIPIC_MORE_VERTICLE);
    let isAcg = this.$route.query.isAcg;
    if(isAcg){
      this.isAcg = isAcg == 1;
    }

    let patternType = this.$route.query.patternType;
    if(patternType){
      this.patternType = Number(patternType);
    }

    this.advList = getAdItem(this.isAcg?AdType.TIPIC_MORE:AdType.TIPIC_MORE_VERTICLE);
  },
  mounted() {
    const { topicId, name, format, tabNmae } = this.$route.query;
    if (topicId) {
      this.topicId = topicId;
      this.titleName = name;
      this.format = format;
      this.tabNmae = tabNmae;
      this.name = name;
      this.getTopicMediaList("refresh");
      // console.log(this.zone,this.tabNmae,this.name);
    }
  },
  methods: {
    async getTopicMediaList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortType: this.navList[this.activeName].sortType,
      };
      if(this.isAcg){
        req['sId'] =  this.topicId;
      }else{
        req['sid'] =  this.topicId;
      }
      let res = null;
      if(this.isAcg){
        res = await this.$Api(queryAcgMediaList, req);
      }else{
        res = await this.$Api(queryVideoMediaList, req);
      }
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      try {
        if (res && res.code == 200) {
          var arr = res.data.list || [];
          if (type === "refresh") {
            // this.videoList = res.data.MediaList;
            this.oldVideoList = arr;
            this.videoList = arr;
          } else {
            this.videoList = this.videoList.concat(arr);
            this.oldVideoList = this.oldVideoList.concat(arr);
          }
          // if(this.advList.length > 0){
          //   let advNum = Math.floor(this.videoList.length / 4);
          //   this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
          //
          //   for (let i = 0; i < advNum; i++) {
          //     let advItem = this.advList[i % this.advList.length];
          //     this.videoList.splice(5 * i + 4, 0, {
          //       adv: advItem,
          //     });
          //   }
          // }
          if (this.advList.length > 0) {
           const advNum = Math.floor((this.videoList.length - 1) / 4);
           this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));

           for (let i = 0; i < advNum; i++) {
            const advItem = this.advList[i % this.advList.length];

            const insertPosition = 5 * i + 4;
            if (insertPosition < this.videoList.length - 1) {
             this.videoList.splice(insertPosition, 0, { adv: advItem });
            }
           }
          }
          // if (this.listAdvs.length > 0 || this.listAdvsVerticel.length > 0) {
          //   let advNum = Math.floor(this.oldVideoList.length / 4);
          //   let advList =
          //     this.format == 0
          //       ? this.listAdvs || []
          //       : this.listAdvsVerticel || [];
          //   let advLength = advList.length;
          //   this.videoList = JSON.parse(JSON.stringify(this.oldVideoList));
          //   // let advLength =
          //   //   this.format == 0
          //   //     ? this.listAdvs.length
          //   //     : this.listAdvsVerticel.length;
          //   if (advLength) {
          //     for (let i = 0; i < advNum; i++) {
          //       let advItem =
          //         this.format == 0
          //           ? this.listAdvs[i % advLength]
          //           : this.listAdvsVerticel[i % advLength];
          //       this.videoList.splice(5 * i + 4, 0, {
          //         adv: advItem,
          //       });
          //     }
          //   }
          // } else {
          //   this.videoList = [].concat(this.oldVideoList || []);
          // }
          // console.log(type);
          // this.AdvNum = AdvNum;
          if (
              this.pageNumber === 1 &&
              this.videoList &&
              this.videoList.length === 0
          ) {
            this.isNoData = true;
          }
          if (!res.data.hasNext || res.data.list.length < req.pageSize) {
            this.finished = true;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.isNoData = false;
      this.loading = true;
      this.getTopicMediaList("refresh");
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getTopicMediaList();
    },
    clickTag(index) {
      if (index != null) {
        this.activeName = index;
      }
      this.pageNumber = 1;
      this.finished = false;
      this.loading = true;
      this.isLoading = true;
      this.isNoData = false;
      this.getTopicMediaList("refresh");
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/topicPage") {
        if (to.query) {
          if (this.topicId != to.query.topicId) {
            this.topicId = to.query["topicId"];
            this.titleName = to.query["name"];
            this.format = to.query["format"];
            this.activeName = 0;
            this.isLoading = true;
            this.getTopicMediaList("refresh");
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.blackSubject{
  width: 100%;
  height: 100%;
  background: #0C0C0C;

  /deep/ .headBar {
    background: #0C0C0C;

    .headTitle {
      font-size: 16px;
      color: rgba(245, 245, 245, 0.58);
    }
  }

  .rowsBox {
    // margin-top: 12px;
    padding: 0 10px;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    .videoBox {
      width: 113px;
      /deep/ .cover {
        height: 166px;
      }
      margin-top: 12px;
    }
  }

  .videoRowsBox {
    // margin-top: 12px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 173px;
      /deep/ .cover {
        height: 98px;
      }
      margin-top: 12px;
    }
  }

  /deep/ .refreshing_warp{
    width: 100%;
  }

  .content {
    //background: #f5f5f5;

    .topicPageContent {
      height: calc(100vh - 48px - 44px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

    }
  }
  .navTabs {
    padding-top: 12px;
  }

  /deep/ .van-tab{
    font-size: 10px;
    padding: 0;
    border: none;
    color: #B09FD9;
    font-weight: 400;
  }

  /deep/ .van-tab--active{
    border-radius: 4px;
    background: #777;
    border: none;
    font-weight: 400;
    color: #FFF;
  }

  /deep/ .van-tabs__nav--card{
    border: none;
    background: #333333;
    margin: 0;
    height: 100%;
  }

  /deep/ .videoBox{
    margin-bottom: 12px;
  }

  /deep/ .van-tabs__line{
    display: none;
  }

  /deep/ .van-tabs__wrap{
    border-radius: 8px;
    height: 26px;
    margin: 0 23px;
    background: #333;
    border: none;
    padding: 2px 4px;
  }

  /deep/ .coverBox{
    background: #0C0C0C;

    .title{
      color: #FFFFFF;
    }
  }

  /deep/ .coverBox{
    background: transparent;

    .videoInfo{
      .title{
        color: #FFFFFF;
      }
    }

    .cover{
      border-radius: 8px;
    }

    .van-image__img{
      border-radius: 8px;
    }
  }

}

.topicPage {
  height: 100%;
  /deep/ .headBar {
    .headTitle {
      font-size: 16px;
      color: #333333;
     font-weight: 600;
    }
  }

  .rowsBox {
    // margin-top: 12px;
    padding: 0 10px;
    //display: flex;
    //flex-wrap: wrap;
    //justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 8px;
    .videoBox {
      width: 113px;
      /deep/ .cover {
        height: 166px;
      }
      margin-top: 12px;
    }
  }

  .videoRowsBox {
    // margin-top: 12px;
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .videoBox {
      width: 173px;
      /deep/ .cover {
        height: 98px;
      }
      margin-top: 12px;
    }
  }

  /deep/ .refreshing_warp{
    width: 100%;
  }

  .content {
    //width: 100%;
    // height: calc(100% - 44px);
    //padding: 0 12px;
    background: #f5f5f5;
    ///deep/ .van-tabs__wrap {
    //  padding-top: 15px;
    //  height: 30px !important;
    //}
    ///deep/ .van-tab {
    //  display: contents;
    //}
    //
    ///deep/ .van-tab__text {
    //  margin-right: 5px !important;
    //}
    //
    ///deep/ .van-tabs__nav--line {
    //  // width: 188px;
    //  // padding-left: 9px;
    //  padding-left: 14px !important;
    //}

    //.navTabTitle {
    //  color: rgb(153, 153, 153);
    //}

    //.navTabTitleActive {
    //  color: #94d6da;
    //}

    .topicPageContent {
      height: calc(100vh - 48px - 44px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

    }
  }
  .navTabs {
    padding-top: 12px;
    @include cardTab();
    /deep/ .van-tabs__wrap {
      padding: 0 16px;
    }
  }
}
</style>
